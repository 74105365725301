.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

@import url('https://combinefonts.hanmesoft.com/cssServer/combine?korFont=HangeulNuri-Bold&engFont=Hammersmith%20One');
@import url('https://combinefonts.hanmesoft.com/cssServer/combine?baseKorFont=Noto%20Sans%20KR%20Regular%20400&korFont=Jal_Haru&engFont=Hammersmith%20One&newFontFamily=Combined');

html,
body {
    font-family: 'Combined', sans-serif;
    font-size: large;
    overflow-x: hidden;
}
/* Always display vertical scroll bar. */
html {
    overflow-y: scroll;
}

h1 {
    font-family: 'HangeulNuri-Bold', sans-serif;
    word-break: keep-all;
}
h2,
h3,
h4,
h5 {
    font-family: 'Combined', sans-serif;
    word-break: keep-all;
}
h1 > a {
    color: #222;
}
h2 > a {
    color: #222;
}
p {
    word-break: keep-all;
}
input {
    font-family: 'Combined', sans-serif;
}

.border-2 {
    border-width: 2px !important;
}
.border-3 {
    border-width: 3px !important;
}

.text-decoration-none {
    text-decoration: none;
}

/*
From https://stackoverflow.com/questions/20256516/highlight-list-item-in-bootstrap
*/
.list-group-hover .list-group-item:hover {
    background-color: #f5f5f5;
}

.search-result-word {
}

.footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 60px; /* Set the fixed height of the footer here */
    line-height: 60px; /* Vertically center the text there */
    background-color: #f5f5f5;
}
/* From https://stackoverflow.com/questions/8865458/how-do-i-vertically-center-text-with-css */
.footer-div {
    height: 60px;
    line-height: 60px;
    text-align: center;
}
.vertical-align-middle {
    display: inline-block;
    vertical-align: middle;
    line-height: normal;
}

.unselectable {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
